import { EErpBasketListProductsView } from 'enums/basket/erpBasketListProductsView.enum';

export const erpBasketListProductsTitleMap = new Map<EErpBasketListProductsView, string>([
  [EErpBasketListProductsView.Analogs, 'Аналоги'],
  [EErpBasketListProductsView.SimilarProducts, 'Похожие товары'],
  [EErpBasketListProductsView.CategorySearch, 'Поиск в категории'],
  [EErpBasketListProductsView.Search, 'Поиск'],
  [EErpBasketListProductsView.AllCatalog, 'Весь каталог'],
]);

export const erpBasketListProductsSubTitleMap = new Map<EErpBasketListProductsView, string>([
  [EErpBasketListProductsView.Analogs, 'Товары идентичные или аналогичные по своим основным свойствам и характеристикам'],
  [EErpBasketListProductsView.SimilarProducts, 'Автоматически подобранные системой товары с похожими характеристиками'],
]);

export const erpBasketListProductsIdsKeysMap = new Map<EErpBasketListProductsView, string>([
  [EErpBasketListProductsView.Analogs, 'substitutionIds'],
  [EErpBasketListProductsView.SimilarProducts, 'similarIds'],
]);

export const ociBasketListProductsTitleMap = new Map<EErpBasketListProductsView, string>([
  [EErpBasketListProductsView.NeedSuitableItems, 'Товары подходящие под потребность'],
  [EErpBasketListProductsView.CategorySearch, erpBasketListProductsTitleMap.get(EErpBasketListProductsView.CategorySearch) ?? ''],
]);
