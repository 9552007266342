<template>
  <div
    v-if="isVisibleText"
    class="mm-basket-erp-products__footer"
  >
    Ничего не устроило?
    <template v-if="(isAnalogTab && isSimilarTab && !isCategoryText) || isCategoryText">
      Посмотрите
    </template>
    <template v-if="isAnalogText || isSimilarText">
      <template v-if="isAnalogText && isSimilarTab">
        <span
          class="link"
          @click="emitSimilar"
        > похожие товары</span> или 
      </template>
      <template v-if="isSimilarText && isAnalogTab">
        <span
          class="link"
          @click="emitAnalogs"
        > аналоги</span> или 
      </template>
      <span :class="{ capitalize: !isAnalogTab || !isSimilarTab }">выберите</span> товар самостоятельно через <span
        class="link"
        @click="emitCategory"
      >поиск в категории</span>
    </template>
    <template v-else-if="isCategoryText">
      <span
        v-if="isSimilarTab"
        class="link"
        @click="emitSimilar"
      >
        похожие товары
      </span>
      <template v-if="isSimilarTab && isAnalogTab">
        или
      </template>
      <span
        v-if="isAnalogTab"
        class="link"
        @click="emitAnalogs"
      >аналоги</span>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { EErpBasketListProductsView } from 'enums/basket/erpBasketListProductsView.enum';

const props = defineProps<{
  currentTab?: EErpBasketListProductsView;
  isSimilarTab: number;
  isAnalogTab: number;
}>();

const emit = defineEmits<{
  (e: 'clickAnalogs'): void;
  (e: 'clickSimilar'): void;
  (e: 'clickCategory'): void;
}>();

const isAnalogText = computed(() => props.currentTab === EErpBasketListProductsView.Analogs);
const isSimilarText = computed(() => props.currentTab === EErpBasketListProductsView.SimilarProducts);
const isCategoryText = computed(() => props.currentTab === EErpBasketListProductsView.CategorySearch && (props.isAnalogTab || props.isSimilarTab));
const isVisibleText = computed(() => props.currentTab && ((props.currentTab !== EErpBasketListProductsView.CategorySearch) || isCategoryText.value));

function emitAnalogs(): void {
  emit('clickAnalogs');
}

function emitSimilar(): void {
  emit('clickSimilar');
}

function emitCategory(): void {
  emit('clickCategory');
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-basket-erp-products__footer {
  width: 100%;
  padding: 0 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $light-green;
  margin-bottom: 40px;

  .link {
    color: $link;
    font-weight: 500;
    cursor: pointer;
  }

  .capitalize {
    text-transform: capitalize;
  }
}
</style>